import React from 'react';
import icons from '../static/icons';

export const HOME_EXAMPLES = {
  shops: [
    {
      name: 'Dr. Shereene Idriss',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Dr_Shereene_Idriss.jpg',
      to: '/shereeneidriss'
    },
    {
      name: 'Alix Earle',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Alix_Earle.jpg',
      to: '/alixearle'
    },
    {
      name: 'Paige DeSorbo',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Paige_DeSorbo.jpg',
      to: '/paigedesorbo'
    },
    {
      name: 'LaToya White',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/LaToya_White.jpg',
      to: '/toyacherrelle'
    },
    {
      name: 'Heather McMahan',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Heather_McMahan.jpg',
      to: '/heatherkmcmahan'
    },
    {
      name: 'Kensington',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Kensington.jpg',
      to: '/kensnation'
    },
    {
      name: 'Larissa Mills',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Larissa_Mills.jpg',
      to: '/larissamills'
    },
    {
      name: 'Cyrus Veyssi',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Cyrus_Veyssi.jpg',
      to: '/cyrusveyssi'
    },
    {
      name: 'Chrissy Rutherford',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Chrissy_Rutherford.jpg',
      to: '/chrissyford'
    },
    {
      name: 'Courtney Grow',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Courtney_Grow.jpg',
      to: '/courtneygrow'
    },
    {
      name: 'Marlien Rentmeester',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Marlien_Rentmeester.jpg',
      to: '/marlienrentmeester'
    },
    {
      name: 'Lindsay Weiss',
      isShop: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Lindsay_Weiss.jpg',
      to: '/cocoincashmere'
    }
  ],
  affiliates: [
    {
      name: 'Jenny Bird',
      isAffiliate: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Jenny.png',
      image_overlay: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Logo_Jenny.png',
      rate: 17,
      to: null
    },
    {
      name: 'Alo',
      isAffiliate: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Alo.png',
      image_overlay: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Logo_Alo.png',
      rate: 15,
      to: null
    },
    {
      name: 'Sandro',
      isAffiliate: true,
      image: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Sandro.png',
      image_overlay: 'https://static.shopmy.us/Home/Partner_Examples/Home_Examples_Logo_Sandro.png',
      rate: 14,
      to: null
    }
  ]
};

export const HOME_CREATOR_ANIMATION_STEPS = [
  {
    title: 'Curate a digital shop',
    subtitle:
      'Easily create a beautiful, customizable storefront for your favorite product recommendations. Share shoppable posts, custom collections of your favorite products and gift guides seamlessly.',
    image: 'https://static.shopmy.us/Home/Creators/Home_Creators_Mobile_Shop_All.png',
    to: '/home/creators/shop',
    action: 'More on Digital Shops'
  },
  {
    title: 'Monetize content with affiliate links',
    subtitle:
      "No applying or reaching out to brands - we've done the heavy lifting for you. Access some of the highest commission rates from 50k+ merchant partners around the world. The best part? Our smart links feature helps ensure your audience never hits a dead end.",
    image: 'https://static.shopmy.us/Home/Creators/Home_Creators_Mobile_Affiliates_All.png',
    to: '/home/creators/affiliates',
    action: 'More on Affiliate Links'
  },
  {
    title: 'Build lucrative brand relationships',
    subtitle:
      "Develop meaningful partnerships with the brands you love thanks to ShopMy's unmatched brand-building features: discover and manage paid collaboration opportunities, chat directly with the most sought-after brands, access custom codes and gifting requests and much more.",
    image: 'https://static.shopmy.us/Home/Creators/Home_Creators_Mobile_Partnerships_All.png',
    to: '/home/creators/partnerships',
    action: 'More on Brand Partnerships'
  }
];

export const HOME_CREATOR_QUOTES = [
  {
    quote: `ShopMy completely revolutionized the way I'm able to share products and connect with my audience. I can make gift guides for socials, share mood boards for styling clients, and write notes for each product to offer tips on styling and fit. Plus, it's extremely easy to use so I can spend all my time curating and sharing instead of linking.`,
    speaker: 'Heather Hurst',
    to: '/pigmami'
  },
  {
    quote:
      "ShopMy has become an indispensable part of my monetization strategy. It goes beyond affiliate links, the platform meaningfully connects me with brands that feel right for my outlet. And when I need help, I get real support from a real human who I don't have to chase down. ShopMy has taken everything about old, clunky platforms and re-engineered it into something smart that works for modern creators.",
    speaker: 'Jess Graves',
    to: '/jessnellgraves',
    speaker_bio: 'Editor, The Love List'
  },
  {
    quote:
      "From its elevated visual esthetic to the ease of use, ShopMy has been key in my social media presence. The brand relationships I've built through the platform have been incredible and the ShopMy team has been so supportive and informative from day one.",
    speaker: 'Jenna Menard',
    speaker_bio: 'Makeup Artist'
  }
];

export const HOME_BRAND_QUOTES = [
  {
    quote:
      'At Larroudé, we have been early adopters of ShopMy, and I cannot recommend it enough to any brand or influencer. It is incredibly easy to navigate for both content creators and brands. Seeding and data tracking work seamlessly. I have been emailing all my industry friends to recommend the platform.',
    speaker: 'Marina Larroudé',
    speaker_bio: 'Co-Founder, Larroudé'
  }
  // {
  //   quote:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.',
  //   speaker: 'Katie Jane Hughes'
  // }
];

export const HOME_BRAND_LOGOS = [
  { brand: 'Alex Mill', domain: 'AlexMill.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/Alex Mill.png' }, // prettier-ignore
  { brand: 'Alo Yoga', domain: 'AloYoga.com' ,logoClass: 'square mobile', logo: 'https://static.shopmy.us/Home/Logos/Alo Yoga.png' }, // prettier-ignore
  { brand: 'Augustinus Bader', domain: 'AugustinusBader.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Augustinus Bader.png' }, // prettier-ignore
  { brand: 'Away', domain: 'Awaytravel.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Away.png' }, // prettier-ignore
  { brand: 'Chanel', domain: 'Chanel.com' ,logoClass: 'square mobile', logo: 'https://static.shopmy.us/Home/Logos/Chanel.png' }, // prettier-ignore
  { brand: 'Clare V', domain: 'ClareV.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Clare V..png' }, // prettier-ignore
  { brand: 'Credo Beauty', domain: 'CredoBeauty.com' ,logoClass: 'square', logo: 'https://static.shopmy.us/Home/Logos/Credo Beauty.png' }, // prettier-ignore
  { brand: 'Crown Affair', domain: 'CrownAffair.com' ,logoClass: 'extra-wide', logo: 'https://static.shopmy.us/Home/Logos/Crown Affair.png' }, // prettier-ignore
  { brand: 'Deciem', domain: 'Deciem.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Deciem.png' }, // prettier-ignore
  { brand: 'Doen', domain: 'shopdoen.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Doen.png' }, // prettier-ignore
  { brand: 'Dolce Vita', domain: 'DolceVita.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/DolceVita.png' }, // prettier-ignore
  { brand: 'Dr. Dennis Gross', domain: 'DrDennisGross.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/Dr. Dennis Gross.png' }, // prettier-ignore
  { brand: 'Drunk Elephant', domain: 'DrunkElephant.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Drunk Elephant.png' }, // prettier-ignore
  { brand: 'Free People', domain: 'FreePeople.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/FP.png' }, // prettier-ignore
  { brand: 'Hanni', domain: 'heyhanni.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Hanni.png' }, // prettier-ignore
  { brand: 'The Honest Company', domain: 'TheHonestCompany.com' ,logoClass: 'wide mobile', logo: 'https://static.shopmy.us/Home/Logos/The Honest Company.png' }, // prettier-ignore
  { brand: 'Hourglass', domain: 'hourglasscosmetics.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/Hourglass.png' }, // prettier-ignore
  { brand: 'Jenni Kayne', domain: 'JenniKayne.com' ,logoClass: 'wide mobile', logo: 'https://static.shopmy.us/Home/Logos/Jenni Kayne.png' }, // prettier-ignore
  { brand: 'Kosas', domain: 'Kosas.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Kosas.png' }, // prettier-ignore
  { brand: 'Little Liffner', domain: 'LittleLiffner.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Little Liffner.png' }, // prettier-ignore
  { brand: 'LoveShackFancy', domain: 'LoveShackFancy.com' ,logoClass: 'extra-wide', logo: 'https://static.shopmy.us/Home/Logos/LSF.png' }, // prettier-ignore
  { brand: 'Lululemon', domain: 'Lululemon.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Lululemon.png' }, // prettier-ignore
  { brand: 'Maje', domain: 'Maje.com' ,logoClass: 'square', logo: 'https://static.shopmy.us/Home/Logos/maje.png' }, // prettier-ignore
  { brand: 'NET-A-PORTER', domain: 'NETAPORTER.com' ,logoClass: 'extra-wide mobile', logo: 'https://static.shopmy.us/Home/Logos/Net-a-porter.png' }, // prettier-ignore
  { brand: 'Naturium', domain: 'Naturium.com' ,logoClass: 'extra-wide', logo: 'https://static.shopmy.us/Home/Logos/Natrium.png' }, // prettier-ignore
  { brand: 'Necessaire', domain: 'Necessaire.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/Necessaire.png' }, // prettier-ignore
  { brand: 'Nike', domain: 'Nike.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/Nike.png' }, // prettier-ignore
  { brand: 'Rhode', domain: 'shoprhode.com' ,logoClass: 'standard', logo: 'https://static.shopmy.us/Home/Logos/Rhode.png' }, // prettier-ignore
  { brand: 'Shopbop', domain: 'Shopbop.com' ,logoClass: 'standard mobile', logo: 'https://static.shopmy.us/Home/Logos/shopbop.png' }, // prettier-ignore
  { brand: 'Tatcha', domain: 'Tatcha.com' ,logoClass: 'wide mobile', logo: 'https://static.shopmy.us/Home/Logos/Tatcha.png' }, // prettier-ignore
  { brand: 'Tower 28', domain: 'tower28beauty.com' ,logoClass: 'wide', logo: 'https://static.shopmy.us/Home/Logos/Tower 28.png' }, // prettier-ignore
  { brand: 'U Beauty', domain: 'UBeauty.com' ,logoClass: 'portrait', logo: 'https://static.shopmy.us/Home/Logos/U Beauty.png' } // prettier-ignore
];

export const HOME_BRAND_TILE_GROUPS = [
  {
    title: 'Fashion',
    tiles: [
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_1.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_1.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_2.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_2.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_3.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_3.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_4.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_4.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_5.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_5.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_6.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_6.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_7.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_7.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_8.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_8.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_9.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_9.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_10.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_10.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_11.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_11.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_12.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_12.png'
      }
    ]
  },
  {
    title: 'Beauty',
    tiles: [
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_13.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_13.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_14.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_14.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_15.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_15.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_16.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_16.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_17.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_17.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_18.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_18.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_19.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_19.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_20.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_20.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_21.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_21.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_22.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_22.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_23.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_23.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_24.png',
        logo: 'https://static.myshlf.us/Home/Tiles/Featured_tile_logo_24.png'
      }
    ]
  },
  {
    title: 'Lifestyle',
    tiles: [
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_25.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_25.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_26.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_26.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_27.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_27.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_28.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_28.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_29.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_29.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_30.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_30.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_31.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_31.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_32.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_32.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_33.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_33.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_34.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_34.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_35.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_35.png'
      },
      {
        cover: 'https://static.shopmy.us/Home/Tiles/Featured_tile_background_36.png',
        logo: 'https://static.shopmy.us/Home/Tiles/Featured_tile_logo_36.png'
      }
    ]
  }
];

export const BRAND_HOME_FEATURES = [
  'Creator Database',
  'Automated Gifting',
  'Affiliate Marketing',
  'Social Listening',
  'Chat with Talent',
  'Campaign Management'
];

export const BRAND_HOME_CTAS = [
  {
    title: 'Discover',
    to: '/home/brands/discover'
  },
  {
    title: 'Engage',
    to: '/home/brands/engage'
  },
  {
    title: 'Track',
    to: '/home/brands/track'
  },
  {
    title: 'Amplify',
    to: '/home/brands/amplify'
  }
];
export const CTA_TITLES = {
  brand: {
    home: "Let's build a successful creator marketing program together.",
    discover: 'Want to see our full roster of top talent?',
    engage: 'Maximize your engagement potential.',
    amplify: 'Supercharge your creator partnerships.',
    track: 'Leverage data to increase performance and ROI '
  },
  creator: {
    home: 'Join the ultimate platform to monetize your influence',
    shop: 'Ready to create your own custom storefront?',
    affiliates: 'Unlock your affiliate revenue potential',
    partnerships: 'Build relationships with your dream brands'
  }
};
export const CTA_SUBTITLES = {
  brand: {
    home: "ShopMy's Full Funnel Approach",
    discover: "ShopMy's Full Funnel Approach",
    engage: "ShopMy's Full Funnel Approach",
    track: "ShopMy's Full Funnel Approach",
    amplify: "ShopMy's Full Funnel Approach"
  },
  creator: {
    home: 'Learn more:',
    shop: 'Learn more:',
    affiliates: 'Learn more:',
    partnerships: 'Learn more:'
  }
};

export const BRAND_HOME_CASE_STUDIES = [
  {
    badge: 'Case Study',
    title: 'See how Hero Cosmetics supercharged their creator program with ShopMy',
    logo: 'https://static.myshlf.us/Brand+Logos/Hero.png',
    cover_image: 'https://static.shopmy.us/Home/Case_Studies/Case_study_Hero.png',
    stats: [
      {
        label: 'ROI',
        value: '10x'
      },
      {
        label: 'Impressions',
        value: '12M'
      }
    ]
  },
  {
    badge: 'Case Study',
    title: 'See how Hero Cosmetics supercharged their creator program with ShopMy',
    logo: 'https://static.myshlf.us/Brand+Logos/Hero.png',
    cover_image: 'https://static.shopmy.us/Home/Case_Studies/Case_study_Hero.png',
    stats: [
      {
        label: 'ROI',
        value: '10x'
      },
      {
        label: 'Impressions',
        value: '12M'
      }
    ]
  },
  {
    badge: 'Case Study',
    title: 'See how Hero Cosmetics supercharged their creator program with ShopMy',
    logo: 'https://static.myshlf.us/Brand+Logos/Hero.png',
    cover_image: 'https://static.shopmy.us/Home/Case_Studies/Case_study_Hero.png',
    stats: [
      {
        label: 'ROI',
        value: '10x'
      },
      {
        label: 'Impressions',
        value: '12M'
      }
    ]
  }
];

export const BRAND_HOME_STEPS = [
  {
    header: 'Discover',
    header_longform: 'Discover top-tier talent',
    subheader: 'Unlock access to our expansive network of +70k elite creators',
    to: '/home/brands/discover',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Discover.png',
    icon: icons.home.nav.discover
  },
  {
    header: 'Engage',
    header_longform: 'Engage with top promoters',
    subheader: 'Save time and cut the noise with a new standard in talent interaction.',
    to: '/home/brands/engage',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Engage.png',
    icon: icons.home.nav.engage
  },
  {
    header: 'Track',
    header_longform: 'Track to optimize performance',
    subheader: 'Measure performance and ROI to maximize the effectiveness of your creator program',
    to: '/home/brands/track',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Track.png',
    icon: icons.home.nav.track
  },
  {
    header: 'Amplify',
    header_longform: 'Amplify your creator program',
    subheader: 'Harness a new level of growth with industry-defining tools & features',
    to: '/home/brands/amplify',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Amplify.png',
    icon: icons.home.nav.amplify
  }
];

export const BRAND_HOME_NAV = [
  {
    header: 'For Brands',
    subheader: 'Discover, engage, and collaborate with top creators',
    to: '/home/brands',
    icon: icons.home.nav.brands
  },
  ...BRAND_HOME_STEPS
];

export const BRAND_HOME_ANIMATIONS = {
  home: [
    {
      title: 'Discover top-tier talent',
      subtitle: `Connect with our vetted pool of 40,000+ creators. Effortlessly identify ideal partners using our advanced filtering and AI-driven selection tools.`,
      video: 'https://static.shopmy.us/Home/Animations/home_brands_discover.mp4',
      to: '/home/brands/discover'
    },
    {
      title: 'Engage with top promoters',
      subtitle:
        'Effortlessly manage your PR and gifting strategies. Foster direct, impactful interactions with creators via our streamlined messaging system.',
      video: 'https://static.shopmy.us/Home/Animations/home_brands_engage.mp4',
      to: '/home/brands/engage'
    },
    {
      title: 'Track to optimize performance',
      subtitle:
        'Harness the power of real-time analytics to track clicks, conversions, and campaign impact. Focus your efforts on partnerships that yield tangible results for sustained brand growth.',
      video: 'https://static.shopmy.us/Home/Animations/home_brands_track.mp4',
      to: '/home/brands/track'
    },
    {
      title: 'Amplify to maximize ROI',
      subtitle:
        'Gain access to industry-defining tools and features to supercharge your program. Boost your best creators, leverage UGC and secure quality content for your brand quickly and with ease.',
      video: 'https://static.shopmy.us/Home/Animations/home_brands_amplify.mp4',
      to: '/home/brands/amplify'
    }
  ],
  discover: [
    {
      title: '70k+ elite content creators',
      subtitle: `Access a diverse network of top experts & creators on major platforms, all ready for brand collaborations.`,
      video: 'https://static.shopmy.us/Home/Animations/discover-search.mp4'
    },
    {
      title: 'Expertly curated lists',
      subtitle: 'Explore hundreds of hand-picked lists of top creators to find the hidden gems for your creator program.',
      video: 'https://static.shopmy.us/Home/Animations/discover-curation.mp4'
    },
    {
      title: 'Advanced filtering',
      subtitle:
        'Leverage our extensive data insights with millions of data points on sales performance, helping you refine your search for the ideal partner.',
      video: 'https://static.shopmy.us/Home/Animations/discover-filter.mp4'
    },
    {
      title: 'AI recommendations',
      subtitle:
        'Our MIT and Stanford trained engineering team has built a world-class talent recommendation system that finds you lookalikes to your current top promoters.',
      video: 'https://static.shopmy.us/Home/Animations/discover-similar-talent.mp4'
    }
  ],
  engage: [
    {
      title: 'Creator-friendly affiliate network',
      subtitle: 'Our streamlined affiliate network simplifies product linking for the modern creator.',
      video: 'https://static.shopmy.us/Home/Animations/collaborate-affiliate.mp4'
    },
    {
      title: 'PR and gifting reimagined',
      subtitle: `Efficiently build and share product lookbooks with hundreds of creators in a single click, saving weeks of back and forth through DM or email.`,
      video: 'https://static.shopmy.us/Home/Animations/engage-gifting.mp4'
    },
    {
      title: 'Chat directly with talent',
      subtitle: 'Our messaging system aligns with how creators prefer to communicate, offering you smooth and efficient interactions hassle-free.',
      video: 'https://static.shopmy.us/Home/Animations/engage-chat.mp4'
    },
    {
      title: 'Brand Placements',
      subtitle:
        'Access spotlight opportunities to get your brand in front of the right talent through our signature events, newsletters and search results.',
      video: 'https://static.shopmy.us/Home/Animations/engage-placements.mp4'
    }
  ],
  track: [
    {
      title: 'Compare your promoters',
      subtitle: 'Evaluate promoter performance in real time, identifying your top creators to inform strategic decisions.',
      video: 'https://static.shopmy.us/Home/Animations/track-performers.mp4'
    },
    {
      title: 'Real-time insights with a social mentions feed',
      subtitle:
        'Leverage social listening to review content and view analytics every time your creator base mentions your brand on Instagram, TikTok and Youtube.',
      video: 'https://static.shopmy.us/Home/Animations/track-mentions.mp4'
    },
    {
      title: 'Creator payments, handled',
      subtitle: 'Enjoy hassle-free financial management with weekly talent payouts and a single monthly invoice for your brand.',
      video: 'https://static.shopmy.us/Home/Animations/collaborate-payments.mp4'
    },
    {
      title: 'Integrations with your POS system',
      subtitle: 'Streamline data synchronization with our seamless integrations with platforms like Shopify, Woocommerce and Salesforce.',
      video: 'https://static.shopmy.us/Home/Animations/track-integrations.mp4'
    }
  ],
  amplify: [
    {
      title: 'Low-lift guaranteed content with Opportunities',
      subtitle:
        'No more hurdles, endless contracts, or lengthy reviews. ShopMy Opportunities helps your brand secure quality content with a lower lift and at a lower cost than traditional campaigns.',
      video: 'https://static.shopmy.us/Home/Animations/amplify-opportunities.mp4'
    },
    {
      title: 'Leverage user generated content',
      subtitle: 'Purchase content directly from talent on the platform to use on your social channels or as white-labeled advertising.',
      video: 'https://static.shopmy.us/Home/Animations/collaborate-ugc.mp4'
    },
    {
      title: 'Create, execute and track campaigns',
      subtitle: 'Efficiently oversee content approval and monitor campaign analytics with our most comprehensive tracking tools.',
      video: 'https://static.shopmy.us/Home/Animations/track-campaigns.mp4'
    }
  ]
};

export const CREATOR_HOME_STEPS = [
  {
    header: 'Digital Shops',
    header_longform: 'Create a Digital Shop',
    subheader: 'A simplified shopping experience for your audience. Easily share your must-have products via your personalized ShopMy storefront.',
    to: '/home/creators/shop',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Shop.png',
    icon: icons.home.nav.shop
  },
  {
    header: 'Affiliate Links',
    header_longform: 'Drive Affiliate Revenue',
    subheader: 'Level up affiliate linking with ShopMy - easier and with more revenue potential than ever.',
    to: '/home/creators/affiliates',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Affiliates.png',
    icon: icons.home.nav.affiliates
  },
  {
    header: 'Brand Partnerships',
    header_longform: 'Build Brand Relationships',
    subheader: "With direct access and unparalleled features, you'll build meaningful connections with the teams at your favorite brands.",
    to: '/home/creators/partnerships',
    hero_image: 'https://static.shopmy.us/Home/Hero/Hero_Brand_Partnerships.png',
    icon: icons.home.nav.partnerships
  }
];

export const CREATOR_HOME_NAV = [
  {
    header: 'For Creators',
    subheader: 'Monetize your influence with the #1 platform for product promotion on social.',
    to: '/home/creators',
    icon: icons.home.nav.creators
  },
  ...CREATOR_HOME_STEPS
];

export const CREATOR_HOME_CTAS = [
  {
    title: 'Digital Shop',
    to: '/home/creators/shop'
  },
  {
    title: 'Affiliate Links',
    to: '/home/creators/affiliates'
  },
  {
    title: 'Brand Partnerships',
    to: '/home/creators/partnerships'
  }
];

export const CREATOR_HOME_IMAGES = {
  home: [
    {
      title: 'Curate a digital shop',
      subtitle:
        'Easily create a beautiful, customizable storefront for your favorite product recommendations. Share shoppable posts, custom collections and gift guides seamlessly - your audience will love it.',
      layered_images: [
        'https://static.shopmy.us/Home/Images/Home_Creator_1a.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_1b.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_1c.png'
      ],
      to: '/home/creators/shop'
    },
    {
      title: 'Monetize content with affiliate links',
      subtitle:
        "No applying or reaching out to brands - we've done the heavy lifting for you. Access some of the highest commission rates from 50k+ merchant partners around the world. The best part? Our smart links feature helps ensure your audience never hits a dead end.",
      layered_images: [
        'https://static.shopmy.us/Home/Images/Home_Creator_2a.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_2b.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_2c.png'
      ],
      to: '/home/creators/affiliates'
    },
    {
      title: 'Build lucrative brand relationships',
      subtitle:
        'Build lucrative partnerships with the brands you love thanks to ShopMy’s unmatched brand-building features: discover and manage paid collaboration opportunities, chat directly with the most sought-after brands, access custom codes and gifting requests and much more.',
      layered_images: [
        'https://static.shopmy.us/Home/Images/Home_Creator_3a.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_3b.png',
        'https://static.shopmy.us/Home/Images/Home_Creator_3c.png'
      ],
      to: '/home/creators/partnerships'
    }
  ],
  shop: [
    {
      title: 'Your central hub',
      subtitle: 'Easily create a beautiful, customizable storefront for your favorite product collections. Your audience will love it.',
      image: 'https://static.shopmy.us/Home/Images/Home_Hub.png'
    },
    {
      title: 'Drag & drop catalog',
      subtitle:
        'Access our user-friendly database of millions of commissionable products and quickly populate collections to share with your audience.',
      image: 'https://static.shopmy.us/Home/Images/Home_Catalog.png'
    },
    {
      title: 'Make every post shoppable',
      subtitle: 'Connect your social accounts and easily monetize your content with shoppable social feeds for Instagram, Youtube and Tiktok.',
      image: 'https://static.shopmy.us/Home/Images/Home_Shoppable_Posts.png'
    }
  ],
  affiliates: [
    {
      title: 'Industry-leading commissions',
      subtitle:
        'We partner with top brands, and always deliver top commission. Access some the highest rates available from the brands you love without the guesswork.',
      image: 'https://static.shopmy.us/Home/Images/Home_Commissions.png'
    },
    {
      title: 'Smart global links',
      subtitle:
        'Commissionable links, lightning fast and always in stock. Your links update based on location and retailer stock levels, so your audience never hits a dead end.',
      image: 'https://static.shopmy.us/Home/Images/Home_Global.png'
    },
    {
      title: 'Seamless weekly payments',
      subtitle: 'Zero hassle. Receive payment for all commissions through our platform on a weekly basis.',
      image: 'https://static.shopmy.us/Home/Images/Home_Payments.png'
    }
  ],
  partnerships: [
    {
      title: 'Paid collaborations + discount codes',
      subtitle: 'Discover and manage paid collaboration opportunities with the brands you love, and access custom codes to share with your audience.',
      image: 'https://static.shopmy.us/Home/Images/Home_Creator_Collaborations.png'
    },
    {
      title: 'Chat with brands and manage gifting',
      subtitle: 'Have direct conversations with the most sought-after brands and manage gifting requests without any obligation to post.',
      image: 'https://static.shopmy.us/Home/Images/Home_Creator_Chat.png'
    },
    {
      title: 'Agent Management Portal',
      subtitle: 'All hands on deck! Give your team access to your ShopMy platform to help supercharge your brand.',
      image: 'https://static.shopmy.us/Home/Images/Home_Agents.png'
    },
    {
      title: 'Creator Events',
      subtitle:
        "We're very social - in real life too. From Coachella to NYFW, ShopMy gives you access to unique event experiences that provide incredible relationship-building opportunities.",
      image: 'https://static.shopmy.us/Home/Images/Home_Events.png'
    }
  ]
};

export const BRAND_HOME_FAQS = {
  home: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:partners@shopmy.us'>brand onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: `Why should I use ShopMy for my influencer program?`,
        answer: `ShopMy is an influencer marketing platform designed to streamline talent discovery, campaign management, and performance tracking. Our intuitive platform centralizes all your creator marketing activities, making it easier than ever to build and manage a top-tier influencer program with minimal effort.`
      },
      {
        question: `How does ShopMy compare to other influencer marketing platforms?`,
        answer: `ShopMy is an influencer marketing solution that simplifies partnerships through effortless seeding, affiliate and paid—driven by direct communication and transparent analytics. We enable your brand to identify and nurture emerging talent, transforming them into top performers for your brand. This holistic approach sets ShopMy apart by providing a more streamlined and effective way to maximize the impact of your influencer marketing efforts.`
      },
      {
        question: `Does ShopMy guarantee ROI?`,
        answer: `ShopMy provides a comprehensive platform for managing your influencer strategy, seamlessly blending your affiliate marketing with paid influencer partnerships. While we don't guarantee specific ROI, our partners experience excellent results by leveraging our core features and tailoring their strategies to fit their creators and target audience. Regular use of the platform and meaningful interactions with creators often lead to reliable and impressive returns on investment.`
      },
      {
        question: `Does ShopMy offer agency services?`,
        answer: `Yes, we provide specialized agency services through our in-house team. Our experts excel in sourcing and managing product seeding, creator collaborations, and optimizing your affiliate program, all at a fraction of the cost of traditional marketing agencies.`
      }
    ]
  },
  discover: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:partners@shopmy.us'>brand onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: `How many creators are on ShopMy?`,
        answer: `ShopMy collaborates with over 70,000 creators and 1,000 new creators apply to join our platform each week. Our extensive network makes it simple to find influencers to work with your brand, helping you discover new creators who are the perfect fit for your marketing goals.`
      },
      {
        question: `What types of creators are using ShopMy?`,
        answer: `ShopMy connects with a diverse range of creators, making it one of the leading platforms for managing influencer partnerships. Our network includes everything from micro and nano creators to mega influencers and celebrities. While we began with a strong focus on beauty—boasting a robust community of beauty experts such as dermatologists, aestheticians, and makeup artists—we also support a wide array of other categories. Whether you're interested in fashion, food, fitness, or other niche areas, ShopMy helps you find and collaborate with creators who perfectly align with your brand.`
      },
      {
        question: `Does ShopMy work with Substack Creators?`,
        answer: `ShopMy collaborates with creators on Substack as well as those on established platforms like Instagram, YouTube, and TikTok. We understand the significant impact of content creators across various platforms, including newer ones like Substack, and are committed to connecting with the best influencers wherever they are.`
      },
      {
        question: `What filtering options are available?`,
        answer: `Our creator database offers a range of powerful filters to help you find the perfect match for your brand. You can search by specific brand mentions, user-generated content, follower count, engagement rate, and more. Additionally, ShopMy is the only influencer marketing software that allows you to filter by sales data, providing your brand with a distinct advantage in identifying top-performing talent.`
      },
      {
        question: `How does ShopMy make talent recommendations?`,
        answer: `ShopMy employs a sophisticated machine learning algorithm, fueled by extensive website traffic and sales data, to analyze over 70,000 creators and deliver precise lookalike recommendations. By evaluating factors such as product category, price point, creator activity, clicks, and sales, ShopMy's creator management system ensures that brands receive optimal talent recommendations. This efficient process saves time and leverages our data and expertise to find creators perfectly aligned with your brand's strategy and goals.`
      },
      {
        question: `Can you curate a custom list for my campaign?`,
        answer: `Our team of experts can create a tailored list of creators specifically suited for your campaign, utilizing our in-depth insights and expertise.`
      },
      {
        question: `How does ShopMy utilize AI?`,
        answer: `ShopMy is a leader in harnessing AI technology to enhance influencer marketing. Our AI-driven approach helps brands move beyond traditional methods of selecting influencers based on “vibes” and aesthetics, focusing instead on data-driven insights. By considering factors such as product category, price point, creator activity, clicks, sales, and average order value, our algorithm ensures that we recommend the most effective creators for your brand.`
      },
      {
        question: `How does ShopMy vet its creators?`,
        answer: `At ShopMy, we have a thorough vetting process for all creators who apply to join our platform. Our Creator Growth team carefully reviews each creator profile to ensure
    a. They produce quality content
    b. They actively engage with brands
    c. Their content is safe and suitable for brand collaborations
This rigorous process helps us maintain a high-quality network of creators who align with our standards and are poised to deliver exceptional value to our brand partners.`
      }
    ]
  },
  engage: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:partners@shopmy.us'>brand onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: `How do I connect with creators on ShopMy?`,
        answer: `ShopMy offers powerful influencer outreach tools that make it easy for brands to connect with their favorite creators. You can chat directly with creators, provide custom discount codes, set unique commission rates, and send product gifts. These features work together to automate and enhance your influencer marketing program, making it seamless and efficient.`
      },
      {
        question: `How can I offer creators custom discount codes?`,
        answer: `Offering custom discount codes is a powerful way to boost brand promotion. With ShopMy, you can easily generate commissionable discount codes for creators to share with their audiences. Our advanced tracking system monitors these sales, and creators can access their codes directly from their accounts for ongoing reference.`
      },
      {
        question: `Can I offer creators different commission rates?`,
        answer: `Yes, ShopMy's commission tracking software allows you to set personalized commission rates for individual creators. Offering higher commission rates is an effective way to boost creator engagement and drive higher conversions, making your partnerships more impactful.`
      },
      {
        question: `How can I make influencer gifting more efficient?`,
        answer: `Our automated gifting feature transforms the creator gifting process, making it incredibly efficient. With a single click, you can send products to multiple creators. Orders are automatically generated and tracked within Shopify, saving you time and minimizing manual effort.`
      },
      {
        question: `Can I use automated gifting even if I'm not on Shopify?`,
        answer: `Absolutely. ShopMy's automated gifting tools for product seeding are available to all brands, regardless of whether they are integrated with Shopify.`
      },
      {
        question: `How can I ensure I'm not wasting product?`,
        answer: `Our gifting feature allows you to set product limits for each creator, ensuring they receive only what they genuinely want to try. This targeted approach helps prevent waste, avoids sending your entire product line and promotes more environmentally sustainable influencer marketing practices.`
      },
      {
        question: `What if I have an upcoming launch or event?`,
        answer: `Help drive a successful launch by utilizing our automatic gifting feature to send product to your top creators ahead of time. Events become easier with ShopMy's digital gifting feature, which eliminates the need to bring product to the event—a scan of a QR code enables creators to have product shipped right to their home.`
      },
      {
        question: `Can my PR agency use ShopMy?`,
        answer: `Yes, many brands collaborate with PR firms to manage product seeding, affiliate programs, and influencer partnerships. Your PR agency can access your ShopMy account using your login credentials.`
      }
    ]
  },
  track: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:partners@shopmy.us'>brand onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: `What analytics can I see on ShopMy?`,
        answer: `ShopMy stands out as a premier platform for measuring influencer marketing success. We provide comprehensive analytics, including detailed insights into brand mentions, clicks, order volume, and sales. Additionally, you can monitor competitor brand mentions, retailer data, and track orders redirected to other sites where your products are available.`
      },
      {
        question: `Are ShopMy's talent analytics better than other influencer platforms?`,
        answer: `While many platforms offer limited visibility, ShopMy delivers in-depth analytics for both brands and creators. Our dashboard provides a complete view of your affiliate program, highlighting which creators are driving sales and the reach of their content. ShopMy also features unique tools, such as retailer-level data, allowing brands to see where their traffic is directed. This makes ShopMy one of the top choices for measuring your influencer marketing ROI.`
      },
      {
        question: `How will I know creators are posting about my brand?`,
        answer: `Our Social Mentions Feed consolidates organic mentions and content created across TikTok, YouTube, and Instagram, including expired Stories. This tool helps brands understand their online presence, discover new creators, and track gifting campaigns. It also offers valuable insights into user-generated content performance, including estimated media value, cost, and ROI per post.`
      },
      {
        question: `How can I compare my brand to others in my industry?`,
        answer: `Our detailed Brand Report offers a thorough comparison of your performance against leading brands on ShopMy. You'll receive scores across all platform metrics and gain insights into how your activities drive success, including the impact of promoters and the volume generated on the platform.`
      },
      {
        question: `I work with several creators who aren't on ShopMy, but I want to track their performance. Can I onboard them?`,
        answer: `Absolutely. We understand that many brands have established loyal brand ambassador programs and ShopMy makes it easy to bring those creators onto our platform. Whether you need to onboard one creator or hundreds, we can add them to your ShopMy account so you can start tracking their content and performance seamlessly.`
      }
    ]
  },

  amplify: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:partners@shopmy.us'>brand onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: `What are the best ways to amplify my program on ShopMy?`,
        answer: `To amplify your program on ShopMy, leverage custom discount codes, tailored commission rates, gifting and partnering with creators on a paid basis. With our Shopify integration, discount codes are seamlessly set up across all your Shopify domains and you can boost commission rates for top performers. Additionally, our Opportunities and Lookbooks features enable you to scale creator campaigns effortlessly without the need for extensive back-and-forth.`
      },
      {
        question: `How do paid collaborations run on the platform?`,
        answer: `Our end-to-end platform and brand collaboration tools streamline collaborations by handling everything from sourcing and content submission to automated influencer payments in a single hub. You can partner with creators through two main approaches: Collaborations, ideal for specific, time-bound projects with detailed creative briefs and content approvals, and Opportunities, which offer a more flexible, long-term engagement.`
      },
      {
        question: `What is Opportunities?`,
        answer: `Opportunities is the next evolution in paid creator partnerships and performance-based marketing. This new offering allows brands to provide fixed payments to creators for guaranteed authentic social mentions. Once accepted, creators can start posting immediately and receive automatic payments upon meeting their commitments. Brands benefit from real-time performance tracking through a leaderboard, with the ability to sort by key metrics such as ROI and Earned Media Value (EMV).`
      },
      {
        question: `Can I reach out to more than one creator at once?`,
        answer: `ShopMy offers a comprehensive set of influencer outreach tools, including our Communications package that allows you to contact up to 2,000 creators simultaneously. This feature is perfect for announcing sales, campaigns, and product launches on a large scale, saving you countless hours and streamlining your outreach process.`
      },
      {
        question: `How does ShopMy's talent CRM work?`,
        answer: `Whether you're targeting micro-influencers or aiming to collaborate with top-tier talent, ShopMy's all-in-one platform streamlines your influencer marketing efforts. Our powerful CRM provides robust analytics, allowing you to monitor every influencer promoting your brand. You can track the sales generated, review the content they've created, and evaluate the overall ROI of your influencer marketing campaigns with unparalleled ease.`
      },
      {
        question: `What is the cost of influencer marketing campaigns?`,
        answer: `At ShopMy, there are no minimum spend requirements for influencer marketing campaigns. Whether you're running a smaller campaign or a larger initiative, our team is dedicated to helping you create and execute a campaign that aligns with your goals, regardless of your budget.`
      }
    ]
  }
};

export const CREATOR_HOME_FAQS = {
  home: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:creatorsupport@shopmy.us'>creator onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: 'How do I earn on ShopMy?',
        answer:
          'ShopMy integrates directly with retailer and brand affiliate programs. You can convert any product URL into a monetizeable URL or simply add products to your storefront and we will automatically convert them for you. Commissions typically range from 10 - 30% depending on the brand or retailer. All commissions and collaborations are tracked on the platform, and you receive payment every Friday via PayPal or Stripe.'
      },
      {
        question: 'How many brands can I work with on ShopMy?',
        answer:
          'The ShopMy network includes 47,000+ brands offering commission as well as over 250+ brand partners that you can chat directly with in the ShopMy platform.'
      },
      {
        question: "What's special about ShopMy compared to other tools?",
        answer:
          'The ShopMy platform was built specifically for the modern social media landscape. It offers the most comprehensive suite of tools for creators to monetize their influence with digital shops, easy affiliate linking and world-class brands to partner with.'
      },
      {
        question: 'Why do I need to apply?',
        answer:
          'ShopMy is an exclusive platform where elite creators can collaborate with brands. Our application process allows us to ensure that you are a good fit for and will benefit from the platform. Our vetting process also allows us to confirm that you have a clean and ethical online presence where you are creating or have potential to create content for the brands we work with.'
      }
    ]
  },
  shop: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:creatorsupport@shopmy.us'>creator onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: 'How do I make my Instagram or Tiktok feed shoppable?',
        answer:
          'You can easily create a collection for your social media and pull posts directly from your feed. Create different collections to organize the posts so your audience can browse through them and find all your product recommendations right at their fingertips.'
      },
      {
        question: 'How can I organize my shop?',
        answer:
          'Shops are made up of "sections" or "tabs" that contain collections of products. Sections are flexible and can be categorized and designed however you like.'
      },
      {
        question: 'How can I add products to my shop?',
        answer:
          'You can create commissionable links in seconds with our extensive drag & drop catalog of millions of products. You can also paste in a product URL from a retailer website, search previously added items, or use our exclusive (and addictive) browser extension, Snapshop.'
      },
      {
        question: 'Can I create gift guides on ShopMy?',
        answer:
          'Gift guides are delightfully easy to build with ShopMy - save time and create beautiful shoppable gift guides just as you would make any other collection.'
      }
    ]
  },
  affiliates: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:creatorsupport@shopmy.us'>creator onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: 'How do links work if my audience is in different countries?',
        answer:
          'ShopMy gives you the ability to have links reroute automatically based on where the user is located - this is a powerful feature called Geolinking. For example, on an item like Bioderma Micellar Water, a US shopper would go to Dermstore, an Australian based shopper would go to Adore Beauty, and a UK shopper would go to LookFantastic - all from clicking the same exact link!'
      },
      {
        question: 'How many brands offer commission on ShopMy?',
        answer:
          'The ShopMy network includes 47,000+ brands offering commission. In addition, you can collaborate with 350+ brand partners directly within the ShopMy platform.'
      },
      {
        question: 'How do I receive payment?',
        answer: 'Payments are sent every Friday through PayPal or Stripe for all commissions and collaborations.'
      }
    ]
  },
  partnerships: {
    subheader: (
      <>
        If you have additional questions, our <a href='mailto:creatorsupport@shopmy.us'>creator onboarding team</a> can help.
      </>
    ),
    questions: [
      {
        question: 'What type of brands are on ShopMy?',
        answer:
          "ShopMy works with brands across all types of verticals including Apparel, Men's Fashion, Beauty, Fitness, Wellness, Home, Food and Drink - and more!"
      },
      {
        question: 'Can I collaborate with brands through ShopMy?',
        answer:
          "Yes! ShopMy's collaboration features allow you to create campaign proposals to share with brands, receive invitations for paid partnerships, and manage negotiations, deliverables, and campaign performance - all in one place."
      },
      {
        question: 'How do I contact brands on the platform?',
        answer:
          'You can contact our brand partners at will with our chat feature. You can send direct messages, request gifting, and propose collaborations all within the chat.'
      }
    ]
  }
};

/*
  Deprecated as of December 2023
*/
const IMAGE_FOLDER_NAME = window.__IS_PRO__ ? 'Shoplist+Pro+Home' : 'Shop+List+Home';
export const IMAGE_OPTIONS_LEFT = [
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p1.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p2.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p3.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p4.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p5.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p6.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p7.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p8.png`
];

export const IMAGE_OPTIONS_RIGHT = [
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p9.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p10.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p11.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p12.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p13.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p14.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p15.png`,
  `https://static.shopmy.us/${IMAGE_FOLDER_NAME}/s1p16.png`
];
